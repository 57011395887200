import { NotificationEventType } from "src/store/types";

export const NOTIFICATION_EVENT_LABEL: {
  [key in NotificationEventType]: string;
} = {
  delivered: "Delivered",
  hard_bounce: "Hard Bounce",
  soft_bounce: "Soft Bounce",
  spam_complaint: "Spam Complaint",
  opened: "Opened",
  clicked: "Clicked",
  transient: "Transient",
  unsubscribe: "Unsubscribe",
  subscribe: "Subscribe",
  auto_responder: "Auto Responder",
  address_change: "Address Change",
  dns_error: "DNS Error",
  spam_notification: "Spam Notification",
  open_relay_test: "Open Relay Test",
  unknown: "Unknown",
  virus_notification: "Virus Notification",
  challenge_verification: "Challenge Verification",
  bad_email_address: "Bad Email Address",
  manually_deactivated: "Manually Deactivated",
  unconfirmed: "Unconfirmed",
  blocked: "Blocked",
  smtp_api_error: "SMTP API Error",
  inbound_error: "Inbound Error",
  dmarc_policy: "DMARC Policy",
  template_rendering_failed: "Template Rendering Failed",
  created: "Postcard Created",
  deleted: "Postcard Deleted",
  mailed: "Postcard Mailed",
  in_transit: "Postcard in Transit",
  in_local_area: "Postcard in Local Area",
  processed_for_delivery: "Postcard Processed for Delivery",
  rerouted: "Postcard Rerouted",
  return_to_sender: "Postcard Returned to Sender",
  accepted: "ACCEPTED",
  no_webhook_configuration: "No Webhook Configuration",
  invalid_response: "Invalid Response",
  request_exception: "Request Exception",
};
